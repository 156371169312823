import { Controller } from "stimulus";
import { debounce, getCSRFToken } from "../../util";
import { showFormInputError, hideFormInputError } from "../../util/gecko_primer";
import { SELECT_SIGN_IN_CTA_EVENT } from "../../mixpanel_config";
import mixpanel from "mixpanel-browser";

const ACTIVE_CLASSES = ["tw-underline", "tw-decoration-primary-500", "tw-decoration-2", "tw-underline-offset-2", "!tw-cursor-default"];
const MAX_PASSWORD_LENGTH = 128;
const MIN_PASSWORD_LENGTH = 8;
const PASSWORD_RX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W|_]).{8,128}$/;

export default class extends Controller {
  static targets = [
    "signInTab", "signUpTab",
    "signInTitle", "signUpTitle",
    "signUpPassword", "signUpSubmit",
    "signUpPassword", "signUpSubmit",
    "logInEmailInput", "signUpEmailInput",
    "analyticsOrigin",
  ];

  /**
   * START: Modal content control (tabbed navigation, etc).
   */
  openSignInModal(e) {
    if (!this._hasAuthModalOnPage()) {
      return window.location = `/account/sign_in?locale=${I18n.locale}`;
    }

    this.updateTitle(e);
    this.signInTabTarget.click();
    Modal.show("auth_modal");
    this._trackSelectSignInCTAEvent(e)
  }

  openSignUpModal(e) {
    if (!this._hasAuthModalOnPage()) {
      return window.location = `/account/sign_up?locale=${I18n.locale}`;
    }

    this.updateTitle(e)
    this.signUpTabTarget.click();
    Modal.show("auth_modal");
    this._trackSelectSignInCTAEvent(e)
  }

  openResendConfirmationModal() {
    if (!this._hasAuthModalOnPage()) {
      return window.location = `/account/confirmation/new?locale=${I18n.locale}`;
    }

    Modal.show("resend_confirmation_modal");
  }

  openResetPasswordModal() {
    if (!this._hasAuthModalOnPage()) {
      return window.location = `/account/password/new?locale=${I18n.locale}`;
    }

    Modal.show("reset_password_modal");
  }

  updateTitle(e) {
    let signInTitle = e?.currentTarget?.dataset?.signInTitle;
    if (signInTitle) {
      this.signInTitleTarget.innerText = e?.currentTarget?.dataset?.signInTitle;
    }

    let signUpTitle = e?.currentTarget?.dataset?.signUpTitle
    if (signUpTitle) {
      this.signUpTitleTarget.innerText = e?.currentTarget?.dataset?.signUpTitle;
    }
  }

  _hasAuthModalOnPage() {
    return !!document.getElementById("auth-modal");
  }

  focusLogInEmailInput(e) {
    setTimeout( () => { this.logInEmailInputTarget.focus() }, 10 );
  }

  focusSignUpEmailInput(e) {
    setTimeout( () => { this.signUpEmailInputTarget.focus() }, 10 );
  }
  /**
   * END: Modal content control (tabbed navigation, etc).
   */


  /**
   * START: Sign up form validation
   */
  validate() {
    if (this.debouncedValidatePassword) {
      return this.debouncedValidatePassword();
    }

    this.debouncedValidatePassword = debounce(this._validatePassword, 100);
  }

  _validatePassword() {
    const password = this.signUpPasswordTarget.value;

    if (password.length < MIN_PASSWORD_LENGTH) {
      window.passwordVerified = false;
      showFormInputError(this.signUpPasswordTarget, I18n.t("validation.short_password"));
    } else if (password.length > MAX_PASSWORD_LENGTH) {
      window.passwordVerified = false;
      showFormInputError(this.signUpPasswordTarget, I18n.t("validation.long_password"));
    } else if (!PASSWORD_RX.test(password)) {
      window.passwordVerified = false;
      showFormInputError(this.signUpPasswordTarget, I18n.t("validation.strong_password"));
    } else {
      hideFormInputError(this.signUpPasswordTarget);
      window.passwordVerified = true;
    }

    if (window.captchaVerified === true && window.passwordVerified === true) {
      this.signUpSubmitTarget.disabled = false;
    } else {
      this.signUpSubmitTarget.disabled = true;
    }
  }
  /**
   * END: Sign up form validation
   */

  _trackSelectSignInCTAEvent(e) {
    mixpanel.track(
      SELECT_SIGN_IN_CTA_EVENT,
      { "origin": e?.currentTarget?.dataset?.analyticsOrigin }
    );
  }
}
