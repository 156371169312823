import { Controller } from "stimulus";
import { createPopper } from '@popperjs/core';
import { buildTooltip } from "../../template/tooltip_templates";
import { EventTooltipLoaded } from "../../events";

export default class extends Controller {
  connect() {
    // Defer loading of dynamic tooltips to prevent heavy initial page load.
    setTimeout(() => {
      document.querySelectorAll("*[data-tooltip]").forEach(this.addEventListeners.bind(this));
      window.addEventListener(EventTooltipLoaded, (e) => {
        e.target.querySelectorAll("*[data-tooltip]").forEach(this.addEventListeners.bind(this));
      });
    }, 200);
  }

  addEventListeners(element) {
    element.addEventListener("mouseenter", (e) => { this.renderTooltip(e.currentTarget) });
    element.addEventListener("mouseleave", (e) => { this.removeTooltip(e.currentTarget) });
  }

  renderTooltip(element) {
    this.removeTooltip(element);

    let tooltipContent = element.dataset.tooltip;
    if (!tooltipContent) {
      return;
    }

    let tooltipType = element.dataset.tooltipType || "short";
    let tooltipPlacement = element.dataset.tooltipPlacement || "top";
    let tooltipTemplate = buildTooltip(tooltipContent, tooltipType, tooltipPlacement);

    element.insertAdjacentHTML("beforeend", tooltipTemplate);
    let tooltipTarget = element.querySelector("[data-gecko-tooltip='true']");
    tooltipTarget.classList.remove("!tw-hidden")

    this.popperInstance = createPopper(element, tooltipTarget, {
      placement: tooltipPlacement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    });
  }

  removeTooltip(element) {
    let tooltipTarget = element.querySelector("[data-gecko-tooltip='true']");
    tooltipTarget?.remove();

    this.popperInstance?.destroy();
  }
}
