import { Controller } from "stimulus";
import { trackEvent } from "../../analytics";

export default class extends Controller {
  trackEvent(e) {
    trackEvent(e?.currentTarget?.dataset?.analyticsEvent, this._getEventAttributes(e))
  }

  _getEventAttributes(e) {
    if (e?.currentTarget?.dataset?.analyticsEventProperties === undefined) {
      return {}
    }

    return JSON.parse(e?.currentTarget?.dataset?.analyticsEventProperties)
  }
}
