export const AD_TEMPLATES = {
  nft: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <div class="tw-p-3">
        ${trackingPixelImageHtml}
        <div class="tw-grid tw-grid-cols-3 tw-gap-2">
          <div>
            <img src=${image} width="105px" class="tw-max-w-[105px] tw-rounded-md">
          </div>
          <div class="tw-col-span-2 tw-flex tw-flex-col tw-gap-4">
            <div>
              <img src=${titleIcon} class="tw-h-5 tw-w-5"><a href="${link}" target="_blank">${title}</a>
            </div>
            <div>
              ${body}
            </div>
          </div>
        </div>
      </div>`;
  },
  coinConverter: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <div class="tw-flex tw-flex-col tw-gap-3 tw-p-2">
        ${trackingPixelImageHtml}
        <div class="tw-flex tw-items-center">
          <img src=${titleIcon} class="tw-h-5 tw-w-5 tw-mr-2"/>
          <span class="tw-text-gray-700 dark:tw-text-moon-200 tw-text-xs tw-font-semibold">
            ${title}
          </span>
        </div>
        <a href="${link}" target="_blank" class="tw-flex tw-flex-col tw-gap-3">
          <div>
            <img src=${image} class="tw-rounded-md tw-w-48">
          </div>
          <div class="tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-font-medium">
            ${body}
          </div>
          <div class="tailwind-reset">
            <button type="button" class="tw-bg-primary-500 dark:tw-bg-primary-400 tw-shadow-primaryShadow !tw-mb-1 hover:tw-bg-primary-600 dark:hover:tw-bg-primary-300 hover:tw-shadow-primaryHover active:!tw-shadow-primaryActive tw-transition-all tw-duration-150 active:tw-translate-y-2 tw-items-center tw-justify-center tw-font-semibold tw-text-inline tw-rounded-lg tw-select-none focus:tw-outline-none tw-px-4 tw-py-2.5 tw-inline-flex tw-w-full">
              <div class="tw-text-white hover:tw-text-white focus:tw-text-white dark:tw-text-primary-900 dark:hover:tw-text-primary-900 dark:focus:tw-text-primary-900 tw-font-semibold tw-text-sm tw-leading-5">
                ${cta}
              </div>
            </button>
          </div>
        </a>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-right">
          <img src=${geckoImageLink} class="tw-h-5 tw-w-5" loading="lazy">
          <div class="tw-text-xs tw-text-gray-500 dark:tw-text-white dark:tw-text-opacity-60 tw-font-medium">
            ${sponsored}
          </div>
        </div>
      </div>`;
  },
  search: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <div class="tw-px-4 tw-flex tw-flex-col tw-gap-1 tw-mt-4 xl:tw-mt-0">
        ${trackingPixelImageHtml}
        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
          <div>
            <img src=${titleIcon} class="tw-w-5">
          </div>
          <div>
            <a href="${link}" target="_blank">${body}</a>
          </div>
        </div>
        <div class="tw-text-xs">
          ${sponsored}
        </div>
      </div>`;
  },
  searchNew: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <a href="${link}" target="_blank" class="tw-flex tw-flex-col tw-gap-2">
        ${trackingPixelImageHtml}
        <div class="tw-text-gray-700 dark:tw-text-moon-100 tw-font-medium tw-text-sm tw-leading-5 tw-flex-grow tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-xs">
          <img src=${titleIcon} class="tw-mr-1 tw-h-4 tw-w-4">
          ${body}
        </div>
        <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium tw-flex-grow tw-flex tw-items-center">
          <img src=${geckoImageLink} width="15px" class="tw-mr-1"> ${sponsored}
        </div>
      </a>`;
  },
  coinOverview: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-2">
        ${trackingPixelImageHtml}
        <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
          <img src=${titleIcon} class="tw-h-6 tw-w-6">
          <div class="tw-text-xs tw-text-gray-700 dark:tw-text-moon-50 tw-font-medium">
            ${title}
          </div>
        </div>
        <div>
          <a href="${link}" target="_blank" class="tw-bg-primary-500 dark:tw-bg-primary-400 tw-shadow-primaryShadow !tw-mb-1 hover:tw-bg-primary-600 dark:hover:tw-bg-primary-300 hover:tw-shadow-primaryHover active:!tw-shadow-primaryActive tw-transition-all tw-duration-150 active:tw-translate-y-2 tw-items-center tw-justify-center tw-font-semibold tw-text-inline tw-rounded-lg tw-select-none focus:tw-outline-none tw-px-2.5 tw-py-1.5 tw-inline-flex">
            <div class="tw-text-white hover:tw-text-white focus:tw-text-white dark:tw-text-primary-900 dark:hover:tw-text-primary-900 dark:focus:tw-text-primary-900 tw-font-semibold tw-text-xs">
              ${cta}
            </div>
          </a>
        </div>
      </div>
      <div class="tw-flex tw-flex-row tw-justify-between tw-items-start">
        <div class="tw-flex-1">
          <div class="tw-text-xs tw-text-gray-700 dark:tw-text-moon-50 tw-font-medium">
            ${headline}
          </div>
          <div class="tw-mt-2 tw-break-words tw-text-xs tw-text-gray-500 dark:tw-text-moon-200">
            ${body}
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-gap-1 tw-text-right">
          <img src=${geckoImageLink} class="tw-h-5 tw-w-5">
          <div class="tw-text-xs tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium">
            ${sponsored}
          </div>
        </div>
      </div>`;
  },
  coinPageButton: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <div class="tw-p-3">
        ${trackingPixelImageHtml}
        <div class="tw-grid tw-grid-cols-3 tw-gap-3">
          <div>
            <img src=${image} class="tw-w-[105px] tw-rounded-md">
          </div>
          <div class="tw-col-span-2 tw-flex tw-flex-col">
            <a href="${link}" target="_blank">
              <div class="tw-flex tw-items-center">
                <img src=${titleIcon} class="tw-h-5 tw-w-5 tw-mr-1">
                <span class="tw-font-medium tw-text-gray-700 dark:tw-text-moon-50 tw-text-sm">${title}</span>
              </div>
              <div>
                <div class="tw-text-gray-700 dark:tw-text-moon-50 tw-text-xs tw-font-semibold tw-mt-2 tw-mb-1">
                  ${tagline}
                </div>
                <div>
                  <span class="tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-font-medium">${body}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>`;
  },
  articleAd: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <a href=${link} rel="sponsored noopener" target="_blank" class="non-body">
        ${trackingPixelImageHtml}
        <div class="non-body tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-p-6 tw-my-6 tw-rounded-xl tw-border-2 dark:tw-border-moon-700 tw-border-gray-200">
          <div class="non-body md:tw-mx-auto tw-my-auto">
            <img src=${titleIcon}>
          </div>
          <div class="tw-col-span-3">
            <div class="non-body tw-font-bold tw-text-gray-900 dark:tw-text-moon-50 tw-text-lg md:tw-text-xl tw-leading-7 tw-flex-grow tw-mb-2">
              ${title}
            </div>
            <div class="non-body tw-font-normal tw-text-gray-500 dark:tw-text-moon-200 tw-text-sm tw-leading-5 tw-flex-grow tw-mb-4">
              ${body}
            </div>
            <div class="non-body tw-flex tw-flex-row tw-items-center tw-gap-1 tw-mb-4">
              <button class="non-body tw-bg-primary-500 dark:tw-bg-primary-400 tw-shadow-primaryShadow tw-items-center tw-justify-center tw-font-semibold tw-text-inline
              tw-rounded-lg tw-select-none focus:tw-outline-none !tw-mb-1 !tw-mr-1 hover:tw-bg-primary-600 dark:hover:tw-bg-primary-300 hover:tw-shadow-primaryHover
              active:tw-shadow-primaryActive tw-transition-all tw-duration-150 active:tw-translate-y-2 tw-px-4 tw-py-2.5 tw-inline-flex">
                <div class="non-body tw-text-xs tw-leading-4 tw-text-white hover:tw-text-white focus:tw-text-white dark:tw-text-primary-900 dark:hover:tw-text-primary-900
                dark:focus:tw-text-primary-900 tw-font-semibold">
                  ${cta}
                </div>
              </button>
              <img src=${geckoImageLink} width="12px" class="non-body !tw-m-0">
              <span class="non-body tw-text-gray-700 dark:tw-text-moon-100 tw-font-semibold tw-text-xs tw-leading-6 tw-flex-grow">${sponsored}</span>
            </div>
          </div>
        </div>
      </a>`
  },
  coinTickerRowAd: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <tr class="tw-h-10 tw-bg-success-50 dark:tw-bg-success-400/10" data-ads-target="banner" >
        ${trackingPixelImageHtml}
        <td></td>
        <td colspan="8" class="tw-py-2">
          <div class="tw-flex tw-gap-x-2">
            <a class="tw-flex tw-items-center tw-text-gray-900 dark:tw-text-moon-50 tw-text-sm tw-leading-5" rel="nofollow" href="${link}" target="_blank">
              <img class="tw-mr-2 !tw-h-6 tw-w-6 tw-object-fill" src="${titleIcon}" />
              ${title}
            </a>

            <div class="tw-flex tw-flex-row tw-items-center tw-gap-x-1">
              <img src=${geckoImageLink} class="tw-h-5 tw-w-5" loading="lazy">
              <div class="tw-text-xs tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium">
                ${sponsored}
              </div>
            </div>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>`
  },
  coinNewsAd: (image, titleIcon, link, title, body, cta, headline, tagline, trackingPixelLink, geckoImageLink, sponsored) => {
    let trackingPixelImageHtml = "";

    if (trackingPixelLink) {
      trackingPixelImageHtml = `<img style="display: none;" src="${trackingPixelLink}">`;
    }

    return `
      <a href="${link}" class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 dark:tw-border-moon-700 tw-pb-5 tw-flex tw-flex-col">
        ${trackingPixelImageHtml}
        <img class="tw-object-cover tw-rounded-lg tw-aspect-[21/11] tw-w-full tw-mb-4" loading="lazy" src="${image}">
        <div class="tw-flex tw-flex-col tw-flex-1 tw-col-span-7">
          <div class="tw-flex-1">
            <div class="tw-font-bold tw-text-gray-900 dark:tw-text-moon-50 tw-text-lg md:tw-text-xl tw-leading-7 tw-mb-4">
              ${title}
            </div>
          </div>
          <div>
            <div class="tw-flex tw-items-center">
              <img src=${titleIcon} class="tw-h-5 tw-w-5 tw-mr-2"/>
              <span class="tw-text-gray-700 dark:tw-text-moon-100 tw-font-semibold tw-text-sm tw-leading-5 tw-mb-1">
                ${body}
              </span>
            </div>
            <div class="tw-font-normal tw-text-gray-500 dark:tw-text-moon-200 tw-text-sm tw-leading-5">
              <time>${sponsored}</time>
            </div>
          </div>
        </div>
      </a>`
  },
}
