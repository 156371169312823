import { Controller } from "stimulus";
import { API_ENDPOINTS, ASSET_ENDPOINTS } from "../config";
import { debounce, throttle, isMobileDevice, castToTarget, sanitizeRecentSearchesLocalStorage } from "../util";
import { EventFocusSearchGlobal, EventPriceLoaded } from "../events";
import { useClickOutside } from "stimulus-use";
import { getCoinsTemplate,
  getExchangesTemplate,
  getCategoriesTemplate,
  getNftsTemplate,
  getGeckoTerminalTokensTemplate,
  getArticlesTemplate,
  getChainsTemplate, } from "../template/search_templates"
import { getSmartResultHtmlByType } from "../template/smart_results_templates";
import { isDownKey,
  isUpKey,
  isEscKey,
  isEnterKey,
  isSlashKey } from "../helpers/keyboard_events"
import { getActiveCurrency } from "../util/currency";
import {
  SEARCH_EVENT,
  SEARCH_EVENT_ORIGIN,
  SELECT_SEARCH_CTA_EVENT,
  SELECT_SIGN_IN_CTA_EVENT
} from "../mixpanel_config";
import { trackEvent } from "../analytics";

const INPUT_DEBOUNCE_DURATION_MS = 250;
const SMART_RESULT_DEBOUNCE_MS = 100;

export default class extends Controller {
  static targets = ["results", "input", "inputOverlay", "searchbar", "searchPopup",
    "searchBackdrop", "defaultChips", "userChips", "smartResult", "chipCoin", "chipNft",
    "chipExchange", "chipCategory", "chipChain", "chipArticle", "chipGt",
    "chipTrendingCoin", "chipTrendingNft", "chipTrendingCategory", "chipTrendingArticle",
    "searchAd"];
  connect() {
    this.onInputChanged = debounce(this.onInputChanged, INPUT_DEBOUNCE_DURATION_MS);
    this.renderSmartResult = debounce(this.renderSmartResult, SMART_RESULT_DEBOUNCE_MS);
    this.fromStickySearchButton = false
    this.url = this.resultsTarget.getAttribute("data-url");

    // Prepare the trending search results as soon as possible
    this._renderTrendingResults()

    // Handle search box focus request from other controllers
    window.addEventListener(EventFocusSearchGlobal, (event) => {
      event.preventDefault();
      this.focusInputBox(event.detail);
    }, true);

    // "/" shortcut to activate search
    window.addEventListener("keyup", (event)=> {
      event.preventDefault();
      if(document.activeElement.tagName === "INPUT") {  return; }
      if(isSlashKey(event)){
        this.focusInputBox();
        trackEvent(SELECT_SEARCH_CTA_EVENT, { "origin": SEARCH_EVENT_ORIGIN["KEYBOARD_SHORTCUT"] });
      }
    }, true);

    // Fix physical device scrolling due to visual viewport size when soft keyboard is open
    this.resultsTarget.addEventListener("scroll", throttle(this.blurInputOnScroll.bind(this), 100));
    window.addEventListener("scroll", throttle(this.blurInputOnScroll.bind(this), 100));

    // Handle outside click to close search popup
    useClickOutside(this);
  }

  async onInputChanged(e) {
    if (e.target.value.trim() === "") {
      this._setDefaultEmptySearchState();
      this.searchAdTarget.classList.remove("tw-hidden");
    } else {
      this.searchAdTarget.classList.add("tw-hidden");
      await this._renderSearchResult(e);
      await this._fetchGtResult(e.target.value.trim());
      if (this.resultsTarget.querySelector(".scroll-container").innerHTML.trim() === "") {
        this._noResultMessage();
      }
    }
  }

  async onFocus(e) {
    // select input text on click
    const input = e.currentTarget;
    input.setSelectionRange(0, input.value.length);
  }

  async _renderSearchResult(e) {
    this._sendGtagTracking(e.target.value.trim());
    const data = await this._fetchSearchResult(e)

    trackEvent(SEARCH_EVENT, { "search_term": e.target.value.trim() });

    // reset the search result
    let resultsTemplate = "";
    resultsTemplate += getCoinsTemplate(data.coins, this.url);
    resultsTemplate += getNftsTemplate(data.nftContracts, this.url);
    resultsTemplate += getExchangesTemplate(data.markets, this.url);
    resultsTemplate += getCategoriesTemplate(data.categories, this.url);
    resultsTemplate += getChainsTemplate(data.assetPlatforms, this.url);
    resultsTemplate += getArticlesTemplate(data.posts, this.url);

    this.resultsTarget.innerHTML = `
      <div class="scroll-container">
        ${resultsTemplate}
      </div>`

    this.defaultChipsTarget.classList.add("tw-hidden");
    this.userChipsTarget.classList.remove("tw-hidden");
    this._handleChipsDisplay(data);

    const firstResult = this.resultsTarget.querySelector(".relevant-results .results-item");

    if (firstResult) {
      this.addSelectIconToResult(firstResult);
      this.renderSmartResult(firstResult);
    }
  }

  async _fetchSearchResult(e) {
    const currentLocale = document.getElementsByTagName("body")[0].getAttribute("data-locale")
    const endpoint = `/${currentLocale}/search_v2?query=${e.target.value.trim()}&vs_currency=${getActiveCurrency()}`;
    const requestOptions = {
      credentials: "same-origin"
    };
    const response = await fetch(endpoint, requestOptions);
    return await response.json();
  }

  _renderTrendingResults() {
    const trendingSearch = JSON.parse(this.resultsTarget.dataset.searchTrending);
    const trendingCategories = JSON.parse(this.resultsTarget.dataset.searchTrendingCategories);

    let resultsTemplate = "";

    resultsTemplate += getCoinsTemplate(trendingSearch.coins, this.url, "trending");
    resultsTemplate += getNftsTemplate(trendingSearch.nft, this.url, "trending");
    resultsTemplate += getCategoriesTemplate(trendingCategories, this.url, "trending");

    this.resultsTarget.innerHTML = `
      <div class="scroll-container">
        ${resultsTemplate}
      </div>`;

    this.defaultChipsTarget.classList.remove("tw-hidden");
    this.userChipsTarget.classList.add("tw-hidden");

    const firstResult = this.resultsTarget.querySelector(".relevant-results .results-item");

    if (firstResult) {
      this.addSelectIconToResult(firstResult);
      this.renderSmartResult(firstResult);
    }
  }

  _renderGtResults(query, error = false) {
    if (error) {
      this.resultsTarget.innerHTML = '<div class="p-2 text-sm">An error occured. Try again later.</div>';
      return;
    }

    if (!query) {
      return;
    }

    this._sendGtagTracking(query);

    let gtResults = "";
    gtResults = this._gtResults;

    if (!gtResults) {
      return;
    }

    if (gtResults.geckoterminal_tokens.length > 0) {
      this.chipGtTarget.classList.remove("tw-hidden")
    } else {
      this.chipGtTarget.classList.add("tw-hidden")
    }

    const geckoTerminalTokens = gtResults.geckoterminal_tokens.slice(0,6);

    let gtResultsTemplate = getGeckoTerminalTokensTemplate(geckoTerminalTokens, false, this.url);
    this.resultsTarget.querySelector(".scroll-container").insertAdjacentHTML('beforeend', gtResultsTemplate);
  }

  _fetchGtResult(query = "") {
    return (this._fetchGtTokensList = fetch(
      `${API_ENDPOINTS[process.env.RAILS_ENV]}/search/geckoterminal_tokens?query=${encodeURIComponent(query)}`,
      {
        credentials: "same-origin"
      }
    )
      .then(response => response.json())
      .then(json => {
        this._gtResults = json;
        this._renderGtResults(query);
        this._hideNoResultDiv();
      })
      .catch(() => {
        this._gtResults = {};
        return {};
      }));
  }

  renderSmartResult(e) {
    if (isMobileDevice(navigator.userAgent)) {
      return;
    }

    this.smartResultTarget.innerHTML = getSmartResultHtmlByType(e);

    this.element.dispatchEvent(
      new CustomEvent(EventPriceLoaded, {
        bubbles: true
      })
    );
  }

  _hideNoResultDiv() {
    let gtResultsDiv = document.getElementsByClassName("geckoterminal-results");
    if(gtResultsDiv.length != 0) {
      document.getElementsByClassName("no-result-message")[0].classList.add('tw-hidden')
    }
  }

  blurInputOnScroll() {
    if (window.innerWidth >= 1200)
      return;

    this.inputTarget.blur();
  }

  clickOutside(e) {
    this.hideSearchPopup();
  }

  showSearchPopup() {
    // inline JS in application.html for coins#index to defeat FID
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.querySelector("body").classList.add("searchv2-popup-open");
    document.querySelector("html").classList.add("searchv2-popup-open");
    this.searchBackdropTarget.classList.remove("tw-hidden");
    this.searchPopupTarget.classList.remove("tw-hidden");
    this.inputTarget.style.outline = 0;
    this.inputTarget.focus();

    if (window.innerWidth < 1200) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "relative";
    }

    if (this.fromStickySearchButton === false) {
      trackEvent(SELECT_SEARCH_CTA_EVENT, { "origin": SEARCH_EVENT_ORIGIN["NAVBAR"] });
    }
  }

  hideSearchPopup() {
    if (!this.searchbarTarget.className.includes("whitelisted-element")) {
      document.querySelector("body").classList.remove("searchv2-popup-open");
      document.querySelector("html").classList.remove("searchv2-popup-open");
      document.querySelector("div.header.dashboard .top-header")?.classList?.remove("tw-hidden");
      this.searchPopupTarget.classList.add("tw-hidden");
      this.searchBackdropTarget.classList.add("tw-hidden");
    } else {
      this.searchbarTarget.classList.remove("whitelisted-element")
    }

    document.body.style.overflow = "initial";
    document.body.style.position = "initial";
  }

  async clearSearch(e) {
    this.inputTarget.value = "";
    this.inputTarget.focus();
    this._setDefaultEmptySearchState();
    this.searchAdTarget.classList.remove("tw-hidden");
  }

  onKeydown(e) {
    // Blurs input to hide soft keyboard on mobile when return key is tapped.
    if (window.innerWidth < 1200) {
      if (isEnterKey(e))
        this.inputTarget.blur();

      return;
    }

    if (isEscKey(e)) {
      this.hideSearchPopup();
    } else if (isEnterKey(e)) {
      const selectedItem = this.resultsTarget.querySelector(".search-selected a");
      if (selectedItem) {
        selectedItem.click()
        // this._submitNavigate(selectedItem);
      }
    } else if (isUpKey(e) || isDownKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      // Set the next/previous result as selected
      const selectedItem = this.resultsTarget.querySelector(".search-selected");
      if (!selectedItem) {
        return;
      }

      const items = Array.from(this.resultsTarget.querySelectorAll(".results-item"));
      const selectedItemIdx = items.findIndex(item => item === selectedItem);

      let newSelectedItem;
      let newSelectedItemIdx = 0;

      if (isDownKey(e)) {
        newSelectedItemIdx = selectedItemIdx + 1;
        if (newSelectedItemIdx < items.length) {
          this.removeSelectIconInResult(selectedItem);
          newSelectedItem = items[newSelectedItemIdx];
          newSelectedItem.classList.add("search-selected");
        }
      } else {
        newSelectedItemIdx = selectedItemIdx - 1;
        if (newSelectedItemIdx >= 0) {
          this.removeSelectIconInResult(selectedItem);
          newSelectedItem = items[newSelectedItemIdx];
          newSelectedItem.classList.add("search-selected");
        }
      }

      if (newSelectedItemIdx === 0) {
        this.resultsTarget.scrollTop = 0;
      }

      if (!newSelectedItem) {
        return;
      }

      //
      // Scroll item into visible view.
      //
      const scrollContainer = this.resultsTarget.querySelector(".scroll-container");
      const scrollContainerTop = scrollContainer.getBoundingClientRect().top;

      const containerTop = this.resultsTarget.getBoundingClientRect().top;
      const containerHeight = this.resultsTarget.getBoundingClientRect().height;

      const itemTop = newSelectedItem.getBoundingClientRect().top;
      const itemHeight = newSelectedItem.getBoundingClientRect().height;

      const itemTopToScrollContainerTopDistance = itemTop - scrollContainerTop;

      let itemTopTarget = itemTop;
      if (isDownKey(e)) {
        // Bottom of the box
        itemTopTarget = containerTop + containerHeight - itemHeight;
      } else {
        // Top of the box
        itemTopTarget = containerTop;
      }

      const scrollTop = itemTopToScrollContainerTopDistance - (itemTopTarget - containerTop);

      if (isDownKey(e)) {
        if (itemTop > itemTopTarget) {
          this.resultsTarget.scrollTop = scrollTop;
        }
      } else {
        if (itemTop < itemTopTarget) {
          this.resultsTarget.scrollTop = scrollTop;
        }
      }

      const firstResult = this.resultsTarget.querySelector(".search-selected");
      this.addSelectIconToResult(firstResult);
      this.renderSmartResult(firstResult);
    }
  }

  onMouseover(e) {
    const selectedItem = this.resultsTarget.querySelector(".search-selected");
    let liTarget;
    if (e.target.classList.contains("results-item")) {
      liTarget = e.target.parentElement.parentElement;
    } else if (e.target.nodeName === "IMG") {
      liTarget = e.target.parentElement.parentElement.parentElement.parentElement;
    } else if (e.target.nodeName === "SPAN") {
      liTarget = e.target.parentElement.parentElement.parentElement;
    } else if (e.target.nodeName === "DIV") {
      liTarget = e.target.parentElement.parentElement;
    }

    if (selectedItem && liTarget) {
      selectedItem.classList.remove("search-selected");
      liTarget.classList.add("search-selected");
    } else if (liTarget) {
      liTarget.classList.add("search-selected");
    }
  }

  focusInputBox(eventDetail) {
    this.fromStickySearchButton = eventDetail?.fromStickyCta

    setTimeout( () => {
      this.inputOverlayTarget.click()
      this.fromStickySearchButton = false
    }, 10 );

    // If mobile device, trigger click event to focus on search bar
    // Due to some mobile browsers that restrict programmatic focus to bring up keyboard as it was not invoked by user gesture
    if (isMobileDevice(navigator.userAgent)) {
      this.inputOverlayTarget.click()
    }
  }

  storeRecentlySearched(e) {
    if (e.currentTarget.dataset.isCoinSearchResult === "false"){
      return;
    }

    const coinData = JSON.parse(e.currentTarget.dataset.smartResult);
    const coinId = +coinData.coin_id;
    let recentSearches = sanitizeRecentSearchesLocalStorage();

    recentSearches = recentSearches.filter(id => id !== coinId);
    recentSearches.unshift(coinId);
    recentSearches = recentSearches.slice(0, 3);
    localStorage.setItem("portfolio-coins-recent-searches", JSON.stringify(recentSearches));
  }

  scrollToList(e) {
    // remove last selected bg color
    this._removeLastSelectedChip();
    e.currentTarget.classList.add("selected");


    const id = e.target.dataset.scrollTarget ?? e.target.parentNode.dataset.scrollTarget;
    const element = this.resultsTarget.querySelector(`#${id}`);

    if (element) {
      let positionY = element.getBoundingClientRect().y - this.resultsTarget.getBoundingClientRect().y;
      if (!this.resultsTarget.querySelector(".results-bottom")) {
        this.resultsTarget.querySelector(".scroll-container").insertAdjacentHTML('beforeend', `<div class="results-bottom tw-h-64"></div>`);
      }
      if (positionY !== 0) {
        this.resultsTarget.scrollBy(0, positionY);
      }
    }
  }

  _handleChipsDisplay(data) {
    if (data.coins.length > 0) {
      this.chipCoinTarget.classList.remove("tw-hidden")
    } else {
      this.chipCoinTarget.classList.add("tw-hidden")
    }

    if (data.nftContracts.length > 0) {
      this.chipNftTarget.classList.remove("tw-hidden")
    } else {
      this.chipNftTarget.classList.add("tw-hidden")
    }

    if (data.markets.length > 0) {
      this.chipExchangeTarget.classList.remove("tw-hidden")
    } else {
      this.chipExchangeTarget.classList.add("tw-hidden")
    }

    if (data.categories.length > 0) {
      this.chipCategoryTarget.classList.remove("tw-hidden")
    } else {
      this.chipCategoryTarget.classList.add("tw-hidden")
    }

    if (data.posts.length > 0) {
      this.chipArticleTarget.classList.remove("tw-hidden")
    } else {
      this.chipArticleTarget.classList.add("tw-hidden")
    }

    if (data.assetPlatforms.length > 0) {
      this.chipChainTarget.classList.remove("tw-hidden")
    } else {
      this.chipChainTarget.classList.add("tw-hidden")
    }
  }

  removeSelectIconInResult(e) {
    const target = castToTarget(e);
    target.classList.remove("search-selected", "dark:tw-bg-moon-700", "tw-bg-gray-100", "tw-rounded-lg");
    // check mouseleave here because hover selection don't have the enter icon
    // if not mouseleave then remove the button here
    if (e.type !== "mouseleave") {
      const rightText = target.querySelector("#rightText");
      const rightTextButton = target.querySelector("#rightTextButton");
      rightText.classList.remove("md:tw-hidden");
      rightTextButton.classList.remove("md:tw-flex");
    }
  }

  addSelectIconToResult(e) {
    const target = castToTarget(e);
    // remove last selected item to avoid double select
    this.resultsTarget.querySelector(".tw-bg-gray-100")?.classList?.remove("search-selected", "dark:tw-bg-moon-700", "tw-bg-gray-100", "tw-rounded-lg");
    target.classList.add("search-selected", "dark:tw-bg-moon-700", "tw-bg-gray-100", "tw-rounded-lg");

    if (e.type === "mouseenter") {
      const rightText = document.querySelector("#rightText.md\\:tw-hidden");
      const rightTextButton = document.querySelector("#rightTextButton.md\\:tw-flex");
      rightText?.classList?.remove("md:tw-hidden");
      rightTextButton?.classList?.remove("md:tw-flex");
    } else {
      const rightText = target.querySelector("#rightText");
      const rightTextButton = target.querySelector("#rightTextButton");
      rightText.classList.add("md:tw-hidden");
      rightTextButton.classList.add("md:tw-flex");
    }
    this._removeAllChipsActivateClasses();
    this._addActiveClassToChip(target.dataset.type);
  }

  _removeAllChipsActivateClasses() {
    this.chipTrendingCoinTarget.classList.remove("selected");
    this.chipTrendingNftTarget.classList.remove("selected");
    this.chipTrendingCategoryTarget.classList.remove("selected");
    // this.chipTrendingArticleTarget.classList.remove("selected");
    this.chipCoinTarget.classList.remove("selected");
    this.chipNftTarget.classList.remove("selected");
    this.chipExchangeTarget.classList.remove("selected");
    this.chipCategoryTarget.classList.remove("selected");
    this.chipChainTarget.classList.remove("selected");
    this.chipArticleTarget.classList.remove("selected");
    this.chipGtTarget.classList.remove("selected");
  }

  _addActiveClassToChip(type) {
    switch (type) {
      case "Coin":
        this.chipTrendingCoinTarget.classList.add("selected");
        this.chipCoinTarget.classList.add("selected");
        break;
      case "Nft":
        this.chipTrendingNftTarget.classList.add("selected");
        this.chipNftTarget.classList.add("selected");
        break;
      case "Exchange":
        this.chipExchangeTarget.classList.add("selected");
        break;
      case "Category":
        this.chipTrendingCategoryTarget.classList.add("selected");
        this.chipCategoryTarget.classList.add("selected");
        break;
      case "GT":
        this.chipGtTarget.classList.add("selected");
        break;
      case "Chain":
        this.chipChainTarget.classList.add("selected");
        break;
      case "Article":
        // this.chipTrendingArticleTarget.classList.add("selected");
        this.chipArticleTarget.classList.add("selected");
        break;
      default:
    }
  }

  async _setDefaultEmptySearchState() {
    await setTimeout(() => { this._renderTrendingResults() }, 50);
    this.defaultChipsTarget.classList.add("tw-hidden");
    this.userChipsTarget.classList.remove("tw-hidden");
  }

  _noResultMessage() {
    this.resultsTarget.innerHTML = `
      <div class="tw-flex tw-flex-col tw-h-full md:tw-block">
        <div class="tw-h-full md:tw-hidden"></div>
        <div class="tw-text-center tw-font-normal tw-text-gray-500 dark:tw-text-moon-200 tw-text-sm tw-leading-5 tw-flex-grow">
          ${I18n.t("search.no_result")}
        </div>
        <div class="tw-h-full md:tw-hidden"></div>
      </div>`;
  }

  _removeLastSelectedChip() {
    this.defaultChipsTarget.querySelector(".selected")?.classList.remove("selected");
    this.userChipsTarget.querySelector(".selected")?.classList.remove("selected");
  }

  _sendGtagTracking(query) {
    // checking gtag exist because adblocker will block it
    if (typeof gtag !== 'undefined') {
      gtag('event', 'searching', {
        'event_category': 'Search',
        'event_label': query
      })
    };
  }
}
