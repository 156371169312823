import { showHideInputError } from "./input";

import dynamicSelect from "./select/select_dynamic";
import searchableSelect from "./select/select_searchable";
import * as utils from "./select/utils";
import { EventGeckoPrimerLoaded } from "../events";

export default () => {
  Alpine.data("select", () => ({
    _gp_refDropdownStatic: null,
    _gp_refDropdownResults: null,
    _gp_refDropdownMessage: null,
    _gp_refDropdownLoading: null,
    _gp_refTextInput: null,
    _gp_isDynamic: false,
    _gp_isSearchable: false,
    _gp_shouldToggleSelection: true,
    _gp_isMultiple: false,
    _gp_initialContent: null,

    value: null,
    open: false,

    init() {
      this._gp_refDropdownStatic = this.$refs.dropdown.querySelector(".gecko-select-dropdown-static");
      this._gp_refDropdownResults = this.$refs.dropdown.querySelector(".gecko-select-dropdown-results");
      this._gp_refDropdownMessage = this.$refs.dropdown.querySelector(".gecko-select-dropdown-message");
      this._gp_refDropdownLoading = this.$refs.dropdown.querySelector(".gecko-select-dropdown-loading");
      this._gp_refTextInput = this.$refs.textInput || this.$refs.dropdown.querySelector("input.gecko-input");

      this._gp_isDynamic = !!this._gp_refDropdownResults;
      this._gp_isSearchable = !!this._gp_refTextInput;
      this._gp_shouldToggleSelection = this.$el.dataset.shouldToggleSelection === "true";
      this._gp_isMultiple = !!this.$refs.selectedChips;

      // Remember initial content for SelectButton component.
      if (this.$el.classList.contains("gecko-select-button")) {
        this._gp_initialContent = this.$refs.content.innerHTML;
      }

      // Allow programmatic access to the content element.
      this.$refs.input._gp_contentTarget = this.$refs.content;

      // Ensures component state is in sync with shadow input's value, can be set programmatically.
      this.$watch("value", this._gp_isMultiple ? this.handleMultipleValueChange.bind(this) : this.handleValueChange.bind(this));

      Object.defineProperty(this.$refs.input, "value", {
        get: () => this.value,
        set: (value) => this.value = this._gp_isMultiple ? value : String(value),
      });


      // Handle dropdown state.
      this.$watch("open", open => {
        if (this._gp_isSearchable) {
          this._gp_refTextInput.classList.toggle("tw-hidden", !open);
          this.$refs.content.classList.toggle("tw-hidden", open);
        }

        if (open === true) {
          this._gp_refDropdownResults?.classList?.add("tw-hidden");
          this._gp_refDropdownMessage.classList.add("tw-hidden");

          // $refs.dropdownStatic is null when there are no items passed. Fallback to message element if that's the case.
          (this._gp_refDropdownStatic || this._gp_refDropdownMessage).classList.remove("tw-hidden");

          // Reset search when dropdown is opened.
          if (this._gp_isSearchable) {
            this._gp_refTextInput.value = null;
          }

          if (!this._gp_isDynamic) {
            const elements = this.$refs.dropdown.querySelectorAll(`.gecko-select-dropdown-static .gecko-select-dropdown-item`);
            elements.forEach(element => element.classList.remove("!tw-hidden"));
          }
        }
      });

      // Prepare statically or dynamically searchable combo boxes.
      if (this._gp_isDynamic) {
        this._prepareDynamicCombo();
      } else if (this._gp_isSearchable) {
        this._prepareSearchableCombo();
      }


      // Redirect focus from the underlying shadow <select> element to our elements.
      this.$refs.input.addEventListener("focus", () => {
        this.$nextTick(() => this.open = true);

        if (this._gp_isSearchable) {
          this._handleSearchableClick();
        }
      });


      // Use nextTick to prevent race condition, where value is set before change
      // event listeners are registered by Stimulus. Ensures that the change event
      // is fired if a value is set on a combobox upon initial page load.
      const selectedValues = Array.from(this.$refs.input.querySelectorAll("[value]:checked")).map(x => x.value);
      this.$nextTick(() => this.value = (this._gp_isMultiple ? selectedValues : selectedValues[0]) || null);

      this.$refs.input.dispatchEvent(new CustomEvent(EventGeckoPrimerLoaded));
    },


    handleClick(e) {
      if (e.target === this._gp_refTextInput) {
        return e.stopPropagation();
      }

      this.open = !this.open;
      this._gp_isSearchable && this._handleSearchableClick(e);
    },

    handleSelect(e) {
      const value = e.currentTarget.dataset.value;

      if (this._gp_isMultiple) {
        this.value.includes(value) ? this.value.splice(this.value.indexOf(value), 1) : this.value.push(value);
        return;
      }

      if (this._gp_shouldToggleSelection && this.value === value) {
        this.value = null;
      } else {
        this.value = value;
      }

      this.open = false;
    },

    handleError(e) {
      showHideInputError(e, this.$refs.container, this.$refs.error);
    },

    handleStaticDropdownAction(e) {
      const { action, item } = e.detail;

      switch(action) {
        case "add":
          this.addStaticDropdownItem(item);
          break;
        case "remove":
          this.removeStaticDropdownItem(item);
          break;
        default:
          throw new Error("Invalid alteration action to static dropdown, only 'add' or 'remove' allowed!");
      }
    },

    handleValueChange(value = this.value) {
      // Ensure that value exists within the dropdown.
      const valueTarget = this.$refs.dropdown.querySelector(`[data-value='${value}']`);
      if (!valueTarget && value != null) {
        return this.value = null;
      }

      // Ensure that select options are in sync.
      this.$refs.input.querySelectorAll(`option:checked`).forEach(option => option.selected = false);

      // Get existing option or create new option (for dynamically loaded items)
      if (value !== null) {
        const optionTarget = this.$refs.input.querySelector(`[value='${value}']`) || this.$refs.input.appendChild(document.createElement("option"));
        optionTarget.value = value;
        optionTarget.innerText = valueTarget.innerText;
        optionTarget.selected = true;
      }


      // Render the dropdown item into content display element
      this.$refs.content.innerHTML = valueTarget?.innerHTML || this._gp_initialContent || "";
      this.$refs.dropdown.querySelector(`i.gecko-select-selected`)?.remove();
      valueTarget?.insertAdjacentHTML("beforeend", `<i class="fa fa-check gecko-select-selected"></i>`);


      // Clear existing dataset values, then copy dataset from value target to content target.
      for (let key in this.$refs.content.dataset) {
        delete this.$refs.content.dataset[key];
      }

      for (const key in (valueTarget?.dataset || [])) {
        this.$refs.content.dataset[key] = valueTarget.dataset[key];
      }


      this.open = false;
      this.$refs.input.dispatchEvent(new Event("change", { bubbles: true }));
    },

    handleMultipleValueChange(value = this.value) {
      this.$refs.dropdown.querySelectorAll(`i.gecko-select-selected`).forEach(icon => icon.remove());
      this.$refs.input.querySelectorAll(`option:checked`).forEach(option => option.selected = false);
      this.$refs.selectedChips.innerHTML = "";

      Array.from(value).forEach(value => {
        const valueTarget = this.$refs.dropdown.querySelector(`[data-value='${value}']`);
        let optionTarget = this.$refs.input.querySelector(`[value='${value}']`);

        // No existing value and option; this value is invalid!
        if (!valueTarget && !optionTarget) {
          return;
        }

        // If the value exist, but option doesn't, create a new option.
        optionTarget ??= this.$refs.input.appendChild(document.createElement("option"));

        // Ensure that the select component and its options are in sync.
        optionTarget.innerText = valueTarget ? valueTarget.innerText : optionTarget.innerText;
        optionTarget.value = value;
        optionTarget.selected = true;

        const selectedChip = this.$refs.selectedChips.appendChild(document.createElement("div"));
        selectedChip.classList.add("gecko-chip");
        selectedChip.innerText = optionTarget.innerText;

        const deleteButton = selectedChip.appendChild(document.createElement("i"));
        deleteButton.classList.add("gecko-chip-remove", "far", "fa-xmark");
        deleteButton.setAttribute("x-on:click", "handleSelect");
        deleteButton.setAttribute("data-value", optionTarget.value);

        valueTarget?.insertAdjacentHTML("beforeend", `<i class="fa fa-check gecko-select-selected"></i>`);
      });


      this.$refs.input.dispatchEvent(new Event("change", { bubbles: true }));
    },


    ...dynamicSelect,
    ...searchableSelect,
    ...utils,
  }));

  Alpine.data("selectV1", () => ({
    value: null,
    open: false,

    init() {
      const initialValue = this.$refs.input.value;

      // Ensures component state is in sync with hidden input's value, can be set programmatically.
      Object.defineProperty(this.$refs.input, "value", {
        get: () => this.value,
        set: (value) => this.value = String(value),
      });

      // Watch for value change, and update component state.
      this.$watch("value", value => {
        // Allows Alpine value to be picked up by browser if within a <form>.
        if (value != null) {
          this.$refs.input.setAttribute("value", value);
        } else {
          this.$refs.input.removeAttribute("value");
        }

        const valueTarget = this.$refs.dropdown.querySelector(`[data-value='${value}']`);
        if (!valueTarget && value != null) {
          return this.value = null;
        }

        this.$refs.content.innerHTML = value == null ? "" : valueTarget.innerHTML;
        this.open = false;

        this.$refs.input.dispatchEvent(new Event("change", { bubbles: true }));
      });

      // Use nextTick to prevent race condition, where value is set before change
      // event listeners are registered by Stimulus. Ensures that the change event
      // is fired if a value is set on a combobox upon initial page load.
      this.$nextTick(() => this.value = initialValue);
    },

    showHideInputError(e) {
      showHideInputError(e, this.$refs.container, this.$refs.error);
    }
  }));
}
