import { deltaTag } from "../util";
import { pricePercentageTemplate, priceTemplate } from "./price_templates"
import { formatCurrency } from "../util/currency";
import { AD_INTEREST_KEYS } from "../config";
import { SELECT_SEARCH_EVENT, SELECT_SEARCH_HIGHLIGHTS } from "../mixpanel_config";
import { searchCategoryEventProperties } from "../analytics";

const liTemplate = ({ imgSrc, itemText, itemSecondaryText = "", rightText = "", searchRedirect, data, type, interestDataKey, interestDataValue, imgType = "image", textColor = "tw-text-gray-500 dark:tw-text-white dark:tw-text-opacity-60", isCoinSearchResult = false , isTrendingTemplate = false, analyticsPropertyId: analyticsPropertyId}) => {
  let img = ""

  if (imgSrc && imgType === "image") {
    img = `<img src="${imgSrc}" class="tw-w-6 tw-mr-2" loading="lazy"/>`
  } else if (imgSrc && imgType === "icon") {
    img = `<i class="fas fa-${imgSrc} dark:tw-opacity-60 tw-w-6 tw-mr-2"></i>`
  } else {
    img = `<div class="tw-w-6 tw-mr-2"></div>`
  }

  let div = document.createElement("div");
  div.className = "results-item"
  div.setAttribute("data-action", "click->analytics-tracker#trackEvent click->targeting-ads#addSearchItemToInterest click->search-v2#storeRecentlySearched mouseenter->search-v2#renderSmartResult mouseenter->search-v2#addSelectIconToResult mouseleave->search-v2#removeSelectIconInResult");
  div.setAttribute("data-smart-result", data);
  div.setAttribute("data-type", type);
  div.setAttribute("data-interest-data-key", interestDataKey);
  div.setAttribute("data-interest-data-value", interestDataValue);
  div.setAttribute("data-is-coin-search-result", isCoinSearchResult.toString());
  div.setAttribute("data-analytics-event", isTrendingTemplate ? SELECT_SEARCH_HIGHLIGHTS : SELECT_SEARCH_EVENT);
  div.setAttribute("data-analytics-event-properties", JSON.stringify(searchCategoryEventProperties(type, analyticsPropertyId, isTrendingTemplate)));
  div.innerHTML = `
    <a href="${searchRedirect}" class="tw-flex tw-justify-between tw-px-2 tw-py-3 tw-rounded-lg">
      <div class="tw-flex tw-items-center">
        ${img}
        <div class="tw-text-gray-700 tw-text-sm tw-font-semibold hover:tw-text-gray-900 dark:tw-text-moon-100  dark:hover:tw-text-moon-50 tw-mr-1">${itemText}</div>
        <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-regular tw-flex-grow">${itemSecondaryText}</div>
      </div>
      <div class="tw-flex tw-items-center" id="rightText">
        ${rightText}
      </div>
      <div class="tw-hidden tw-flex-row tw-items-center tw-gap-1" id="rightTextButton">
        <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium">
          ${I18n.t("search.select")}
        </div>
        <span class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-gray-400 dark:tw-bg-moon-500 tw-px-1.5 tw-py-0.5 !tw-px-2">
          <div class="tw-text-xs tw-leading-4 tw-text-white dark:tw-text-moon-50 tw-font-medium">
            <i class="far fa-fa-regular fa-arrow-turn-down-left"></i>
          </div>
        </span>
      </div>
    </a>
  `;

  return div.outerHTML;
};

export const getCoinsTemplate = (coins, url, templateType = "search") => {
  if (coins && coins.length) {
    let customTitle = I18n.t(templateType === "trending" ? "search.trending_search" : "search.cryptocurrencies");
    let isCoinSearchResult = templateType !== "trending";

    const coinsListTemplate = coins
      .map((coin) => {
        if (templateType === "trending") {
          coin = coin.item
        }

        let urlPath = new URL(url)
        urlPath.searchParams.set("id", coin.id)
        urlPath.searchParams.set("type", "coin")

        if (templateType == "trending") {
          urlPath.searchParams.set("source", "trending")
        }

        return liTemplate({
          imgSrc: coin.thumb,
          itemText: coin.name,
          itemSecondaryText: coin.symbol,
          rightText: pricePercentageTemplate(coin.data.price_change_percentage_24h),
          searchRedirect: urlPath,
          data: JSON.stringify(coin),
          type: "Coin",
          interestDataKey: AD_INTEREST_KEYS["coins"],
          interestDataValue: coin.id,
          isCoinSearchResult: isCoinSearchResult,
          isTrendingTemplate: templateType === "trending",
          analyticsPropertyId: coin.id,
        });
      })
      .join("");

    if (!coinsListTemplate) {
      return "";
    }

    return `
        <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-coin-results">
          <span class="tw-shrink-0 tw-basis-auto">${customTitle}</span>
          <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
        </div>
        <div class="list-reset relevant-results">
          ${coinsListTemplate}
        </div>`;
  }
  return ``;
};

export const getExchangesTemplate = (exchanges, url) => {
  if (exchanges && exchanges.length) {
    const exchangesListTemplate = exchanges
      .map((exchange) => {
        let urlPath = new URL(url)
        urlPath.searchParams.set("id", exchange.id)
        urlPath.searchParams.set("type", "exchange")

        return liTemplate({
          imgSrc: exchange.thumb,
          itemText: exchange.name,
          rightText: exchange.data.trust_score_rank === null ? "" : `#${exchange.data.trust_score_rank}`,
          searchRedirect: urlPath,
          data: JSON.stringify(exchange),
          type: "Exchange",
          analyticsPropertyId: exchange.id,
        });
      })
      .join("");

    if (!exchangesListTemplate) {
      return "";
    }
    return `
      <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-exchange-results">
        <span class="tw-shrink-0 tw-basis-auto">${I18n.t("search.exchanges")}</span>
        <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
      </div>
      <div class="list-reset relevant-results">
        ${exchangesListTemplate}
      </div>`;
  }
  return ``;
};

export const getCategoriesTemplate = (categories, url, templateType = "search") => {
  let customTitle = I18n.t(templateType === "trending" ? "search.trending_categories" : "search.categories");

  if (categories && categories.length) {
    const categoriesListTemplate = categories
      .map((category) => {
        let urlPath = new URL(url)
        urlPath.searchParams.set("id", category.id)
        urlPath.searchParams.set("type", "category")

        if (templateType === "trending") {
          let textColor = category.market_cap_1h_change >= 0 ? "tw-text-primary-500" : "tw-text-red-500";
          return liTemplate({
            imgSrc: "list-alt",
            itemText: category.name,
            rightText: deltaTag(category.market_cap_1h_change, 2),
            searchRedirect: urlPath,
            data: JSON.stringify(category),
            type: "Category",
            interestDataKey: AD_INTEREST_KEYS["categories"],
            interestDataValue: category.data.slug,
            imgType: "icon",
            textColor: textColor,
            isTrendingTemplate: true,
            analyticsPropertyId: category.data.slug,
          });
        } else {
          return liTemplate({
            imgSrc: "list-alt",
            itemText: category.name,
            rightText: pricePercentageTemplate(category.data.market_cap_change_percentage_24h),
            searchRedirect: urlPath,
            data: JSON.stringify(category),
            type: "Category",
            interestDataKey: AD_INTEREST_KEYS["categories"],
            interestDataValue: category.data.slug,
            imgType: "icon",
            analyticsPropertyId: category.data.slug,
          });
        }
      })
      .join("");

    if (!categoriesListTemplate) {
      return "";
    }
    return `
      <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-category-results">
        <span class="tw-shrink-0 tw-basis-auto">
        ${customTitle}
        </span>
        <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
      </div>
      <div class="list-reset relevant-results">
        ${categoriesListTemplate}
      </div>`;
  }
  return ``;
};

export const getNftsTemplate = (nfts, url, templateType = "search") => {
  let customTitle = I18n.t(templateType === "trending" ? "search.trending_nft" : "search.nft");
  if (nfts && nfts.length) {
    const nftsListTemplate = nfts
      .map((nft) => {
        let urlPath = new URL(url)
        urlPath.searchParams.set("id", nft.id)
        urlPath.searchParams.set("type", "nft")

        if(templateType == "trending"){
          urlPath.searchParams.set("utm_source", "trending_search")
          urlPath.searchParams.set("utm_medium", "coingecko")
        }

        return liTemplate({
          imgSrc: nft.thumb,
          itemText: nft.name,
          itemSecondaryText: "",
          rightText: deltaTag(nft.data.floor_price_in_usd_24h_percentage_change, 2),
          searchRedirect: urlPath,
          data: JSON.stringify(nft),
          type: "Nft",
          isTrendingTemplate: templateType === "trending",
          analyticsPropertyId: nft.id,
        });
      })
      .join("");
    if (!nftsListTemplate) {
      return "";
    }
    return `
      <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-nft-results">
        <span class="tw-shrink-0 tw-basis-auto">${customTitle}</span>
        <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
      </div>
      <div class="list-reset relevant-results">
        ${nftsListTemplate}
      </div>`;
  }
  return ``;
};

const gtTokensCustomTemplate = (imgSrc, imgNetworkSrc, name, liquidity, tokenUrl, data) => {
  let img = ""
  let imgNetwork = ""
  let liquidityFormatted = formatCurrency(liquidity, "usd", false, false, true);

  if (imgSrc) {
    img = `<img src="${imgSrc}" class="tw-w-6 tw-mr-2" loading="lazy"/>`
  }

  if (imgNetworkSrc) {
    imgNetwork = `<img src="${imgNetworkSrc}" class="tw-w-6 tw-mr-2" loading="lazy"/>`
  };

  return `
    <div data-smart-result='${data}' data-type="GT" class="results-item" data-action="click->analytics-tracker#trackEvent mouseenter->search-v2#renderSmartResult mouseenter->search-v2#addSelectIconToResult"
    data-analytics-event="${SELECT_SEARCH_EVENT}" data-analytics-event-properties='${JSON.stringify(searchCategoryEventProperties('geckoterminal'))}'>
      <a href="${tokenUrl}" target="_blank" class="tw-flex tw-justify-between tw-px-2 tw-py-3 tw-rounded-lg">
        <div class="tw-flex tw-items-center">
          ${img}
          ${imgNetwork}
          <div class="tw-text-gray-700 tw-text-sm tw-font-semibold hover:tw-text-gray-900 dark:tw-text-moon-100  dark:hover:tw-text-moon-50 tw-mr-1">${name}</div>
          <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-regular tw-flex-grow">${I18n.t("search.liquidity")}: ${liquidityFormatted}</div>
        </div>
        <div class="tw-flex tw-items-center" id="rightText">
          <i class="fas fa-external-link dark:tw-opacity-60 tw-w-6"></i>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-hidden" id="rightTextButton">
          <div class="tw-text-xs tw-leading-4 tw-text-gray-500 dark:tw-text-moon-200 tw-font-medium">
            ${I18n.t("search.select")}
          </div>
          <i class="tw-inline-flex tw-items-center tw-rounded-md  tw-bg-gray-400 dark:tw-bg-moon-500 tw-px-2 tw- py-0.5
            tw-text-xs tw-leading-4 tw-text-white  dark:tw-text-moon-50 tw-font-medium fa-regular fa-arrow-turn-down-left">
          </i>
        </div>
      </a>
    </div>`;
}

export const getGeckoTerminalTokensTemplate = (geckoterminal_tokens, hasScore = false, url) => {
  if (geckoterminal_tokens && geckoterminal_tokens.length) {
    const geckoTerminalTokensTemplate = geckoterminal_tokens
      .map((token, _idx) => {
        let urlPath = new URL(url)
        let tokenUrl = token.url.concat("?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults")
        urlPath.searchParams.set("id", token.name)
        urlPath.searchParams.set("type", "geckoterminal_token")
        return gtTokensCustomTemplate(
          token.base_token_logo_url,
          token.network_logo_url,
          token.name,
          token.liquidity,
          tokenUrl,
          JSON.stringify(token));
      })
      .join("");
    return `
      <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-gt-results">
        <span class="tw-shrink-0 tw-basis-auto">${I18n.t("search.geckoterminal")}</span>
        <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
      </div>
      <div class="list-reset relevant-results">
        ${geckoTerminalTokensTemplate}
      </div>`;
  }
  return ``;
};

export const getArticlesTemplate = (articles, url) => {
  if (articles && articles.length) {
    const articleListTemplate = articles
      .map((article) => {
        let urlPath = new URL(url)
        urlPath.searchParams.set("id", article.id)
        urlPath.searchParams.set("type", "article")
        return liTemplate({
          imgSrc: "newspaper",
          itemText: article.name,
          searchRedirect: urlPath,
          type: "Article",
          imgType: "icon"
        });
      })
      .join("");

    if (!articleListTemplate) {
      return "";
    }
    return `
      <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-article-results">
        <span class="tw-shrink-0 tw-basis-auto">${I18n.t("search.articles")}</span>
        <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
      </div>
      <div class="list-reset relevant-results">
        ${articleListTemplate}
      </div>`;
  }
  return ``;
};

export const getChainsTemplate = (chains, url) => {
  if (chains && chains.length) {
    const chainsListTemplate = chains
      .map((chain) => {
        let urlPath = new URL(url)
        urlPath.searchParams.set("id", chain.id)
        urlPath.searchParams.set("type", "chain")

        return liTemplate({
          imgSrc: "link",
          itemText: chain.name,
          rightText: pricePercentageTemplate(chain.data.tvl_change_percentage_30d),
          searchRedirect: urlPath,
          data: JSON.stringify(chain),
          type: "Chain",
          interestDataKey: AD_INTEREST_KEYS["chains"],
          interestDataValue: chain.api_symbol,
          imgType: "icon"
        });
      })
      .join("");

    if (!chainsListTemplate) {
      return "";
    }
    return `
      <div class="tw-flex tw-gap-x-2 tw-items-center tw-text-gray-500 dark:tw-text-moon-200 tw-text-xs tw-my-2" id="search-chain-results">
        <span class="tw-shrink-0 tw-basis-auto">${I18n.t("search.chains")}</span>
        <div class="tw-w-full tw-border-t tw-border-gray-200 dark:tw-border-moon-700"></div>
      </div>
      <div class="list-reset relevant-results">
        ${chainsListTemplate}
      </div>`;
  }
  return ``;
};

export const getCompareCoinsTemplate = (coin, searchInput, { onInputDisplay = false, selected = false } = {}) => {
  const coinDataToShow = onInputDisplay ? formatCurrency(coin.price, "usd") : `(${coin.symbol.toUpperCase()})`
  const selectedIcon = !onInputDisplay && selected ? '<i class="fa fa-check gecko-select-selected"></i>' : '';

  return `
      <div class="tw-flex tw-items-center tw-gap-x-2"
      data-search-input="${searchInput}"
      data-coin='${JSON.stringify(coin)}'
      >
        <img class="tw-h-6 tw-w-6" src="${coin.image}" alt="${coin.name}"/>
        ${coin.name} ${coinDataToShow}
      </div>
      ${selectedIcon}
    `;
}
