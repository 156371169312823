import KevelBaseController from "./kevel_base_controller";
import { AD_TEMPLATES } from "../../../template/ad_templates";
import { sendImpression } from "../../../util/kevel";
import showAds from "../../../util/show_ads";

export default class extends KevelBaseController {
  static targets = ["body"];

  connect() {
    this.clicked = false;
    this.adData = null;

    if (showAds() && this.viewportAd()) {
      this.loadAdIfAdAtViewport();
    }
  }

  async sendImpression() {
    if (showAds()) {
      this.adProp = this.getAdProp();

      if (this.dataKeywordExist()) {
        this.attachKeywordToRequestBody();
      };

      if (this.adDataNotLoaded()) {
        await this.loadAdData();
      }

      if (this.adDataNotNull()) {
        this._renderAd();
        this._sendImpression();
      }
    }
  }

  _renderAd() {
    const templateName = this.adProp.templateName;
    let adBody = "";
    this.impressionLinkArr = [];

    this.adData.forEach(adData => {
      adBody += AD_TEMPLATES[templateName](
        adData.image,
        adData.titleIcon,
        adData.link,
        adData.title,
        adData.body,
        adData.cta,
        adData.headline,
        adData.tagline,
        adData.trackingPixelLink,
        this.bodyTarget.dataset.cgLogoUrl,
        this.bodyTarget.dataset.sponsored,
      );
      this.impressionLinkArr.push(adData.impressionLink);
    });

    this.bodyTarget.innerHTML = adBody;
    this.bodyTarget.classList.remove("tw-hidden");
  }

  async _sendImpression() {
    if (!this.clicked) {
      this.clicked = this.impressionLinkArr.map(async link => {
        return await sendImpression(link);
      });
    }
  }
}
