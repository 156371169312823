const baseTooltipTemplate = (type, content, placement) => `
<div role="tooltip" data-gecko-tooltip="true" class="gecko-tooltip gecko-tooltip-${type} !tw-hidden gecko-override-links
 gecko-tooltip-extra-padding">
  <span class="gecko-tooltip-content">${content}</span>
  <div data-popper-arrow></div>
</div>
`;

const VALID_TOOLTIP_TYPES = ["long", "simple", "short"];
export const buildTooltip = (content, type = "short", placement = "top") => {
  if (!VALID_TOOLTIP_TYPES.includes(type)) {
    type = "short";
  }

  return baseTooltipTemplate(type, content, placement);
};
